/**
 * A class that captures exceptions and sends them to a monitoring service.
 */
export abstract class ErrorMonitor {
  /**
   * @return id of the captured exception
   * @param exception
   */
  abstract captureException(exception: unknown): Promise<string>

  /**
   * Adds breadcrumb to the current scope, which will be attached to every event in the scope.
   * @param breadcrumb
   * @param hint
   */
  abstract addBreadcrumb(breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Promise<void>

  /**
   * You can enrich events with additional data by adding your own event processors.
   * Event processors will run on every event sent after they are added.
   */
  abstract addEventProcessor(processor: EventProcessor): void

  /**
   * Sets a key:value that will be sent with the next events.
   */
  abstract setContext(name: string, value: Record<string, unknown> | null): void

  /**
   * Sets the user context that will be sent with the next events.
   */
  abstract setUser(user: Record<string, unknown> | null): void
}

export interface Breadcrumb {
  level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
  message?: string
  data?: Record<string, unknown>
  // more fields can be added as needed, and already supported by abstracted implementation (ie sentry)
}

export type BreadcrumbHint = Record<string, unknown>

export interface Event {
  event_id?: string
  message?: string
  release?: string
  environment?: string
  fingerprint?: string[]
  breadcrumbs?: Breadcrumb[]
  // more fields can be added as needed, and already supported by abstracted implementation (ie sentry)
}

export interface EventHint {
  event_id?: string
  syntheticException?: Error | null
  originalException?: unknown
  data?: unknown
  // more fields can be added as needed, and already supported by abstracted implementation (ie sentry)
}

export interface EventProcessor {
  (event: Event, hint: EventHint): PromiseLike<Event | null> | Event | null
  id?: string
}

export class NoopErrorMonitoringService extends ErrorMonitor {
  captureException(exception: unknown) {
    return Promise.resolve('')
  }

  addBreadcrumb(breadcrumb: Breadcrumb, hint?: BreadcrumbHint) {
    return Promise.resolve()
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addEventProcessor(processor: EventProcessor): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setContext(name: string, value: Record<string, unknown> | null): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUser(user: Record<string, unknown> | null): void {}
}
